import api from './index'
import authHeader from './auth-header'

class serviceSystem {

    getUserLogData() {
        return api.get('system/log/staff/all', { headers: authHeader() })
    }

    getDepartmentAll() {
        return api.get('system/department/all', { headers: authHeader() })
    }

    getMaritalStatusAll() {
        return api.get('system/maritalStatus/all', { headers: authHeader() })
    }

    getLawyerTypeAll() {
        return api.get('system/lawyerType/all', { headers: authHeader() })
    }

    getRelationAll() {
        return api.get('system/relation/all', { headers: authHeader() })
    }

    getAppointmentTypeAll() {
        return api.get('system/appointmentType/all', { headers: authHeader() })
    }

    getPositionAll() {
        return api.get('system/position/all', { headers: authHeader() })
    }
    deletePositionById(id) {
        return api.delete('system/position/delete/' + id, { headers: authHeader() })
    }
    getPositionByDepartmentId(id) {
        return api.get('system/position/departmentId/' + id, { headers: authHeader() })
    }

    insertPosition(formData) {
        return api.post('system/position/create', formData, { headers: authHeader() })
    }

    getTrainingTypeAll() {
        return api.get('system/trainingType/all', { headers: authHeader() })
    }

    getDegreeLevelAll() {
        return api.get('system/degreeLevel/all', { headers: authHeader() })
    }
    getProvinceAll() {
        return api.get('system/province/all', { headers: authHeader() })
    }

    getCourtAll() {
        return api.get('system/court/all', { headers: authHeader() })
    }

    getCourtByProvinceId(id) {
        return api.get('system/court/province/' + id, { headers: authHeader() })
    }

    getCourtById(id) {
        return api.get('system/court/' + id, { headers: authHeader() })
    }

    getLanguageSkills() {
        return api.get('system/skill/language', { headers: authHeader() })
    }

    getComputerSkills() {
        return api.get('system/skill/computer', { headers: authHeader() })
    }

    getSkillAll() {
        return api.get('system/skill/all', { headers: authHeader() })
    }

    getMedalTypeAll() {
        return api.get('system/medalType/all', { headers: authHeader() })
    }

    getMedalTypeByParent(parent) {
        return api.get('system/medalType/parent/' + parent, { headers: authHeader() })
    }

    getMedalTypeByNonParent() {
        return api.get('system/medalType/parent/none', { headers: authHeader() })
    }

    getMedalTypeById(id) {
        return api.get('system/medalType/' + id, { headers: authHeader() })
    }

    insertMedalType(formData) {
        return api.post('system/medalType/create', formData, { headers: authHeader() })
    }

    getCaseLevelOfCrime() {
        return api.get('system/caseLevelOfCrime/all', { headers: authHeader() })
    }

    getLetterTypeAll() {
        return api.get('system/letterType/all', { headers: authHeader() })
    }
    getCaseLetterTypeAll() {
        return api.get('system/caseLetterType/all', { headers: authHeader() })
    }
    getCaseLetterTypeByLetterTypeId(id) {
        return api.get('system/caseLetterType/letterType/id/' + id, { headers: authHeader() })
    }

    getCaseLetterType(code) {
        return api.get('system/caseLetterType/' + code, { headers: authHeader() })
    }

    getCaseTaskTypeAll() {
        return api.get('system/taskType/all', { headers: authHeader() })
    }

    getCaseEvidenceTypeAll() {
        return api.get('system/caseEvidenceType/all', { headers: authHeader() })
    }

    insertCaseEvidenceType(formData) {
        return api.post('system/caseEvidenceType/create', formData, { headers: authHeader() })
    }

    insertCaseLetterType(formData) {
        return api.post('system/caseLetterType/create', formData, { headers: authHeader() })
    }

    insertCaseTaskType(formData) {
        return api.post('system/caseTaskType/create', formData, { headers: authHeader() })
    }

    getCaseCrimeTypeAll() {
        return api.get('system/caseCrimeType/all', { headers: authHeader() })
    }
    insertCaseCrimeType(formData) {
        return api.post('system/caseCrimeType/create', formData, { headers: authHeader() })
    }

    getCaseDecisionTypeAll() {
        return api.get('system/caseDecisionType/all', { headers: authHeader() })
    }

    getCaseDecisionType() {
        return api.get('system/caseDecisionType/prosecutor', { headers: authHeader() })
    }

    getJudgeCaseDecisionType() {
        return api.get('system/caseDecisionType/judge', { headers: authHeader() })
    }

    insertCaseDecisionType(formData) {
        return api.post('system/caseDecisionType/create', formData, { headers: authHeader() })
    }
}

export default new serviceSystem()